import React, { FC, useCallback } from 'react'

import css from 'styled-jsx/css'
import { useSetting } from '@ui/hooks'
import { imagePath } from '@ui/helpers'
import SfLink from '../next/SfLink'

interface LogoProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  titleOnly?: boolean
  redirectable?: boolean
}

const Logo: FC<LogoProps> = ({ titleOnly = false, className, redirectable = true }) => {
  const [storeSetting] = useSetting('store')
  const logo = storeSetting?.general?.logo?.image
  const title = storeSetting?.general.name ?? ''
  const logoClass = ['logo', ...[className ? [className] : []]].join(' ')
  const Wrapper = useCallback(
    (prop: { children: JSX.Element }) => {
      return redirectable ? (
        <SfLink pathName="/" className={logoClass}>
          {prop.children}
        </SfLink>
      ) : (
        <div className={logoClass}>{prop.children}</div>
      )
    },
    [redirectable, logoClass]
  )

  return (
    <Wrapper>
      <>
        <style jsx global>
          {globalStyle}
        </style>
        <figure className="image">
          {!titleOnly && logo && (
            <picture>
              <source srcSet={imagePath(logo)} />
              {storeSetting?.fallback_img?.[logo || ''] ? (
                <source srcSet={imagePath(storeSetting?.fallback_img?.[logo || ''])} />
              ) : (
                <></>
              )}
              <img src={imagePath(logo)} alt={title || 'logo storefont'} />
            </picture>
          )}
        </figure>
        {(titleOnly || !logo) && <div className="text">{title}</div>}
      </>
    </Wrapper>
  )
}

const globalStyle = css.global`
  .logo {
    color: #303030;
    font-family: 'Raleway', sans-serif;
    font-size: 32px;
    font-weight: 900;
    // letter-spacing: 3px;
    line-height: 37px;

    @media screen and (max-width: 768px) {
      font-size: 22px;
    }

    img {
      max-height: 100px;
      max-width: 300px;
      width: var(--logo-width) !important;
      object-fit: contain;
      object-position: left;

      @media screen and (max-width: 768px) {
        height: 50px;
      }
    }
  }
`

export default Logo
