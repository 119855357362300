import getConfig from 'next/config'

const configs = () => getConfig?.()?.publicRuntimeConfig || {}

export const shopHelpPath = (imgPath: string, c = configs()) =>
  `${c.cdnBasePath}${c.cdnShopHelp}${c.cdnImage}${imgPath}`

export const listRouterStatic = [
  'shipping-policy',
  'return-policy',
  'terms-of-service',
  'privacy',
  'payment-methods',
  'about-us'
]

export const isShowAboutUS = () => {
  const domain = typeof window !== 'undefined' ? window?.location?.hostname : ''
  const arrDomain = [
    'noerror.onselless.dev',
    'quantity.onselless.dev',
    'rosiaa.com',
    'hansihandy.com',
    'pinkchloe.com',
    'julidostore.us',
    'avacharm.com',
    'lavelbeauty.us',
    'irisboutique.us',
    'violetcharm.us',
    'sklingerie.us',
    'avasecret.us',

    'coutora.com', //14/6/2023
    'fabalia.com',
    'chiceti.com',
    'bevashop.com',
    'elalift.com',

    'monocharm.com', //29/11
    'eleganchic.com',
    'bloomydove.com',
    'calormas.com',
    'loomtext.com',
    'denimblack.com',
    'maidenfit.com',
    'lilasha.com',
    'truecups.com',
    'bevawear.com',
    'venuscharm.us',
    'maracharm.com',
    'jetjeans.us',
    'bloomypink.com',
    'comfysfit.com',
    'lisacharm.com',
    'wmstylist.com',
    'rosiedelight.com',
    'bluechicstores.us',
    'misatailor.com',
    'mellycharm.com',

    'jollywish.us', // 1/3/2024 https://crossian.slack.com/archives/C01B17QGTB6/p1709279148771949
    'miracharm.com',
    'texwix.com',
    'missboutiques.us',
    'emilycharm.com',
    'miacharm.com',
    'foxyseniors.com',

    'coolmance.us', // 20/02/2024 https://crossian.slack.com/archives/C01B17QGTB6/p1710906903972009
    'puliam.com',
    'lowenstore.com',
    'unwaverin.com',

    'lynsiecharm.com',
    'ellydove.com', // 19/04
    'judewest.com',
    'luxelana.com',
    'mencomfy.com',
    'gentyfit.com',
    'glamlacy.com',
    'bluecomfy.com',
    'manzera.com',
    'lalachics.com',
    'eragirl.com',
    'vintibloom.com',
    'coolflex.us',

    'lanenow.com',

    'amazinghub.us',

    'davystore.com',
    'trixieboutiques.com', // 21/5
    'tyracharm.com',
    'mellycharm.com',
    'lovejessiebra.com'
  ]
  return arrDomain?.includes(domain)
}
