import { FC, useEffect, useState } from 'react'
import css from 'styled-jsx/css'
import SfLink from '@ui/components/next/SfLink'
import { iconPath } from '@ui/helpers'
import { useIsShowShophelp, usePaygates, useSetting, useVersionCookie } from '@ui/hooks'
import getConfig from 'next/config'
import { isShowAboutUS } from '@ui/helpers/path'
// import { logger } from '@ui/analytics'

const config = getConfig().publicRuntimeConfig
// const shophelpUrl = config?.shophelpUrl
// const deepLink = config?.shophelpDeepLink

const Footer: FC<Types.PlainObject> = ({ className }) => {
  const [storeSetting] = useSetting('store')
  const title = storeSetting?.general?.name || ''
  const url = storeSetting?.general?.domain || ''

  const year = new Date().getFullYear()
  const copyrightText = title || url?.replace(/^http(|s):\/\//, '')

  const paygates = usePaygates()

  const isShowShophelp = useIsShowShophelp()
  const phone = paygates?.paypalex?.contact?.phone || paygates?.creditcard?.contact?.phone || ''
  const address =
    paygates?.paypalex?.contact?.address || paygates?.creditcard?.contact?.address || ''
  const version = useVersionCookie()
  const isVpl1 = version == 'vpl1' || storeSetting?.version == 'vpl1'

  // const openShophelp = () => {
  //   logger.logShopHelp('OPEN')
  //   const desktopLink = `${shophelpUrl}/pre-shophelp?fromShop=${window.location.hostname}`
  //   const isMobile = isAndroidIos()
  //   window.open(!isMobile ? desktopLink : `${deepLink}`)
  // }
  const [showAboutUs, setShowAboutUs] = useState(false)
  useEffect(() => {
    setShowAboutUs(isShowAboutUS())
  }, [])
  return (
    <footer className={`contact-form-footer container is-fullwidth ${className ?? ''}`}>
      <style jsx global>
        {globalStyle}
      </style>
      <div className="container">
        <div className="columns">
          <div className="column is-5">
            <div className="contact-form-footer__contact">
              {storeSetting && storeSetting.configurations?.show_company && (
                <>
                  <div className="contact__text">Crossian LLC</div>
                  <div className="contact__address company">
                    <figure className="icon--inline image">
                      {isVpl1 ? (
                        <img
                          data-src={iconPath('location.svg')}
                          className={`lazyload lazypreload`}
                          alt=""
                        />
                      ) : (
                        <img src={iconPath('location.svg')} alt="" />
                      )}
                    </figure>
                    <div className="address__text">
                      5th floor, Pax Sky building, 63-65 Ngo Thi Nham Str., Hanoi, Vietnam
                    </div>
                  </div>
                  <div className="contact__address company">
                    <figure className="icon--inline image">
                      {isVpl1 ? (
                        <img
                          data-src={iconPath('phone.svg')}
                          className={`lazyload lazypreload`}
                          alt=""
                        />
                      ) : (
                        <img src={iconPath('phone.svg')} alt="" />
                      )}
                    </figure>
                    <div className="address__text">(+84) 24 6688 7429</div>
                  </div>
                  <div className="contact__address company">
                    <figure className="icon--inline image">
                      {isVpl1 ? (
                        <img
                          data-src={iconPath('compass.svg')}
                          className={`lazyload lazypreload`}
                          alt=""
                        />
                      ) : (
                        <img src={iconPath('compass.svg')} alt="" />
                      )}
                    </figure>
                    <div className="address__text">Registration number: 0109267724</div>
                  </div>
                  <div className="contact__group mt-5">
                    <SfLink pathName="/contact" className="contact__link button">
                      Contact Us
                    </SfLink>
                  </div>
                </>
              )}
              {storeSetting && !storeSetting.configurations?.show_company && (
                <>
                  <div className="contact__text">How can we help you?</div>
                  <div className="contact__group">
                    <SfLink pathName="/contact" className="contact__link button">
                      Contact Us
                    </SfLink>
                    {isShowShophelp && (
                      <div className="contact__shophelp">
                        <span>Served by</span>
                        {isVpl1 ? (
                          <img
                            data-src={iconPath('shophelp.svg')}
                            className={`lazyload lazypreload`}
                            alt="Served by shophelp"
                          />
                        ) : (
                          <img src={iconPath('shophelp.svg')} alt="Served by shophelp" />
                        )}
                      </div>
                    )}
                  </div>

                  {storeSetting?.configurations?.show_phone && (
                    <div className="contact__address">
                      <figure className="icon--inline image">
                        {isVpl1 ? (
                          <img
                            data-src={iconPath('phone.svg')}
                            className={`lazyload lazypreload`}
                            alt=""
                          />
                        ) : (
                          <img src={iconPath('phone.svg')} alt="" />
                        )}
                      </figure>
                      <div className="address__text">{phone}</div>
                    </div>
                  )}
                  {storeSetting?.configurations?.show_address && (
                    <div className="contact__address">
                      <figure className="icon--inline image">
                        {isVpl1 ? (
                          <img
                            data-src={iconPath('location.svg')}
                            className={`lazyload lazypreload`}
                            alt=""
                          />
                        ) : (
                          <img src={iconPath('location.svg')} alt="" />
                        )}
                      </figure>
                      <div className="address__text">{address}</div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="column is-offset-1-tablet is-3">
            <div className="contact-form-footer__group">Order</div>
            <div className="contact-form-footer__menu">
              <SfLink pathName="/trackings/search">Order Tracking</SfLink>
            </div>
            <div className="contact-form-footer__menu">
              <SfLink pathName="/static/exchanges-returns">Exchanges & Returns</SfLink>
            </div>
            <div className="contact-form-footer__menu">
              <SfLink pathName="/static/orders-shipping">Order & Shipping</SfLink>
            </div>
          </div>
          <div className="column is-3">
            <div className="contact-form-footer__group">Resources</div>
            {/* {storeSetting && storeSetting?.configurations?.show_about_us && (
              <div className="contact-form-footer__menu">
                <SfLink pathName="/static/about-us">About Us</SfLink>
              </div>
            )} */}
            <div className="contact-form-footer__menu">
              <SfLink pathName="/static/terms-of-service">Terms of Service</SfLink>
            </div>
            <div className="contact-form-footer__menu">
              <SfLink pathName="/static/privacy">Privacy Policy</SfLink>
            </div>
            {/* <div className="contact-form-footer__menu">
              <SfLink pathName="/static/payment-methods">Payment Methods</SfLink>
            </div> */}
            {showAboutUs && (
              <div className="contact-form-footer__menu">
                <SfLink pathName="/static/about-us">About Us</SfLink>
              </div>
            )}
          </div>
        </div>
        <hr className="contact-form-footer__divider" />
        <div className="contact-form-footer__copyright">
          <a
            href={`https://${config?.webSelless || ''}`}
            target="_blank"
            className="web-selless"
            rel="noreferrer"
          >
            Powered by <b>Selless</b>
          </a>
          <SfLink pathName="/">
            © {year} {copyrightText}. All rights reserved.
          </SfLink>
          <a href="#" className="go-top">
            Go to top
            <figure className="image">
              {isVpl1 ? (
                <img
                  data-src={iconPath('arrow_up.svg')}
                  className={`lazyload lazypreload`}
                  alt=""
                />
              ) : (
                <img src={iconPath('arrow_up.svg')} alt="" />
              )}
            </figure>
          </a>
        </div>
      </div>
    </footer>
  )
}

const globalStyle = css.global`
  .contact-form-footer {
    background-color: #242424;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 32px;
    position: relative;
    padding-top: 48px;
    padding-bottom: 16px;

    /* &__contact {
    display: flex;
    flex-direction: column;
    align-items: center;
  } */

    .contact {
      &__text {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 16px;
      }

      &__group {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
      }

      &__link {
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        padding: 8px 32px;
        margin-right: 16px;
        height: auto;
        color: #fff;
        background-color: #2d9cdb;
        border-color: transparent;
        border-radius: 4px;

        &:hover {
          color: #fff;
          text-decoration: underline;
        }
      }

      &__shophelp {
        display: flex;
        align-items: center;

        span {
          font-size: 10px;
          color: white;
          margin-top: 0;
          margin-right: 6px;
        }
      }

      &__address {
        display: flex;
        align-items: center;

        &.company {
          height: 50px;
        }

        .address__text {
          font-size: 12px;
          line-height: 24px;

          a {
            color: inherit;
            pointer-events: none;
            cursor: text;
          }
        }
      }
    }

    .icon {
      &--inline {
        min-width: 18px;
        margin-right: 12px;

        img {
          width: 18px;
          height: 18px;
        }
      }
    }

    &__group {
      font-weight: bold;
      font-size: 15px;
      line-height: 32px;
      margin-bottom: 16px;
    }

    &__menu {
      font-size: 12px;
      line-height: 24px;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      a {
        color: #828282;
      }
    }

    &__logo {
      margin-bottom: 18px;

      .logo {
        color: #fff;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
      }
    }

    &__divider {
      background-color: #333;
      margin-top: 34px;
      margin-bottom: 14px;
    }

    &__copyright {
      display: flex;
      justify-content: space-between;

      a {
        color: #828282;
        font-size: 12px;
        line-height: 24px;
      }
    }

    .go-top {
      .image {
        display: inline-block;
        align-items: center;
        margin-left: 4px;

        img {
          width: 10px;
          height: 10px;
        }
      }
    }

    @media screen and (max-width: 768px) {
      padding-top: 32px;
      padding-bottom: 32px;

      &__contact {
        margin-bottom: 36px;
      }

      &__divider {
        display: none;
      }

      &__copyright {
        flex-direction: column;
        align-items: center;
        margin-top: 36px;

        a {
          margin-bottom: 24px;
        }
      }

      .go-top {
        font-size: 15px;
        line-height: 32px;

        .image {
          img {
            width: 15px;
            height: 15px;
          }
        }
      }

      .contact {
        &__text {
          text-align: center;
        }

        &__group {
          flex-direction: column;
        }

        &__link {
          margin-right: 0;
        }

        &__shophelp {
          margin-top: 16px;
        }
      }
    }
  }
`

export default Footer
